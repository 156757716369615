import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e59a72de = () => interopDefault(import('../pages/begeleiders/index.vue' /* webpackChunkName: "pages/begeleiders/index" */))
const _122ee651 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _75b246c6 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _43bebfbe = () => interopDefault(import('../pages/ouders/index.vue' /* webpackChunkName: "pages/ouders/index" */))
const _0971a1a7 = () => interopDefault(import('../pages/over.vue' /* webpackChunkName: "pages/over" */))
const _697340cf = () => interopDefault(import('../pages/registreren.vue' /* webpackChunkName: "pages/registreren" */))
const _4860c69e = () => interopDefault(import('../pages/vragen.vue' /* webpackChunkName: "pages/vragen" */))
const _4c195ea1 = () => interopDefault(import('../pages/vragen/index.vue' /* webpackChunkName: "pages/vragen/index" */))
const _4a62bd59 = () => interopDefault(import('../pages/vragen/_slug.vue' /* webpackChunkName: "pages/vragen/_slug" */))
const _3bccb596 = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/index.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/index" */))
const _310b22e2 = () => interopDefault(import('../pages/begeleiders/tarieven.vue' /* webpackChunkName: "pages/begeleiders/tarieven" */))
const _7eefa4b2 = () => interopDefault(import('../pages/begeleiders/toolbox.vue' /* webpackChunkName: "pages/begeleiders/toolbox" */))
const _61994a40 = () => interopDefault(import('../pages/ouders/begeleiders/index.vue' /* webpackChunkName: "pages/ouders/begeleiders/index" */))
const _5b96c9ac = () => interopDefault(import('../pages/ouders/omgeving.vue' /* webpackChunkName: "pages/ouders/omgeving" */))
const _12544a42 = () => interopDefault(import('../pages/ouders/tarieven.vue' /* webpackChunkName: "pages/ouders/tarieven" */))
const _c3d977a0 = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/trainingen.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/trainingen" */))
const _dad19894 = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/webinars.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/webinars" */))
const _26f49806 = () => interopDefault(import('../pages/ouders/begeleiders/zoeken.vue' /* webpackChunkName: "pages/ouders/begeleiders/zoeken" */))
const _3f39f826 = () => interopDefault(import('../pages/begeleiders/netwerkactiviteiten/_slug.vue' /* webpackChunkName: "pages/begeleiders/netwerkactiviteiten/_slug" */))
const _5fe2a8f8 = () => interopDefault(import('../pages/ouders/begeleiders/_slug.vue' /* webpackChunkName: "pages/ouders/begeleiders/_slug" */))
const _10784509 = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _24fb6d8f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _aa4b9932 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/begeleiders",
    component: _e59a72de,
    name: "begeleiders"
  }, {
    path: "/blogs",
    component: _122ee651,
    name: "blogs"
  }, {
    path: "/contact",
    component: _75b246c6,
    name: "contact"
  }, {
    path: "/ouders",
    component: _43bebfbe,
    name: "ouders"
  }, {
    path: "/over",
    component: _0971a1a7,
    name: "over"
  }, {
    path: "/registreren",
    component: _697340cf,
    name: "registreren"
  }, {
    path: "/vragen",
    component: _4860c69e,
    children: [{
      path: "",
      component: _4c195ea1,
      name: "vragen"
    }, {
      path: ":slug",
      component: _4a62bd59,
      name: "vragen-slug"
    }]
  }, {
    path: "/begeleiders/netwerkactiviteiten",
    component: _3bccb596,
    name: "begeleiders-netwerkactiviteiten"
  }, {
    path: "/begeleiders/tarieven",
    component: _310b22e2,
    name: "begeleiders-tarieven"
  }, {
    path: "/begeleiders/toolbox",
    component: _7eefa4b2,
    name: "begeleiders-toolbox"
  }, {
    path: "/ouders/begeleiders",
    component: _61994a40,
    name: "ouders-begeleiders"
  }, {
    path: "/ouders/omgeving",
    component: _5b96c9ac,
    name: "ouders-omgeving"
  }, {
    path: "/ouders/tarieven",
    component: _12544a42,
    name: "ouders-tarieven"
  }, {
    path: "/begeleiders/netwerkactiviteiten/trainingen",
    component: _c3d977a0,
    name: "begeleiders-netwerkactiviteiten-trainingen"
  }, {
    path: "/begeleiders/netwerkactiviteiten/webinars",
    component: _dad19894,
    name: "begeleiders-netwerkactiviteiten-webinars"
  }, {
    path: "/ouders/begeleiders/zoeken",
    component: _26f49806,
    name: "ouders-begeleiders-zoeken"
  }, {
    path: "/begeleiders/netwerkactiviteiten/:slug",
    component: _3f39f826,
    name: "begeleiders-netwerkactiviteiten-slug"
  }, {
    path: "/ouders/begeleiders/:slug",
    component: _5fe2a8f8,
    name: "ouders-begeleiders-slug"
  }, {
    path: "/blogs/:slug",
    component: _10784509,
    name: "blogs-slug"
  }, {
    path: "/",
    component: _24fb6d8f,
    name: "index"
  }, {
    path: "/*",
    component: _aa4b9932,
    name: "*"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
